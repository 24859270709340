<template>
  <div>
    <v-dialog max-width="1000" v-model="dialog" v-if="dialog" scrollable>
      <v-card>
        <v-system-bar window>
          {{ item && item.type ? item.type.description : "—" }}
          <v-spacer /><v-btn icon @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-system-bar
        >
        <v-card-text class="ma-0 pa-0">
          <v-toolbar color="transparent" flat>
            <PersonItem :value="item.student" />
          </v-toolbar>
          <v-divider />
          <v-alert v-if="showHalfDayWarning48" type="error" text prominent
            >Dieser Halbtag wurde weniger als 48 Stunden im Voraus eingereicht!
          </v-alert>
          <v-alert v-if="showHalfDayWarning5" type="error" text prominent
            >Es wurden bereits {{ halfDayCount.used }} Halbtage bezogen! Es sind
            nur {{ halfDayCount.max }} Halbtage pro Semester erlaubt!
          </v-alert>
          <v-container>
            <v-row>
              <v-col cols="12" md="5">
                <v-list>
                  <v-list-item v-if="item.halfDay">
                    <v-list-item-content>
                      <v-list-item-subtitle>Datum</v-list-item-subtitle>
                      <v-list-item-title
                        ><DateValue :value="item.startDate" />,
                        {{
                          item.type.code == "halfDayMorning"
                            ? "vormittag"
                            : "nachmittag"
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="!item.halfDay">
                    <v-list-item-content>
                      <v-list-item-subtitle>Beginn</v-list-item-subtitle>
                      <v-list-item-title
                        ><DateValue :value="item.startDate" />,
                        {{ item.startTime ? item.startTime : "morgens" }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="!item.halfDay">
                    <v-list-item-content>
                      <v-list-item-subtitle>Ende</v-list-item-subtitle>
                      <v-list-item-title
                        ><DateValue :value="item.endDate" />,
                        {{ item.endTime ? item.endTime : "abends" }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Status</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ item.status.description }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Kategorie </v-list-item-subtitle>
                      <v-list-item-title>
                        <LookupValue :value="item.category" hideCode />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Entschuldigungstext Schüler*in
                      </v-list-item-subtitle>
                      {{ item.description ? item.description : "–" }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="hasRole('teacher')">
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Kommentar Klassenlehrkraft
                      </v-list-item-subtitle>

                      {{ item.comments ? item.comments : "–" }}
                    </v-list-item-content>
                  </v-list-item>

                  <JustificationStatusMessage
                    v-for="(logEntry, index) in item.log"
                    :value="logEntry"
                    :key="`log-${index}`"
                  />
                </v-list>
              </v-col>
              <v-divider vertical />

              <v-col cols="12" md="7">
                <RegisterLessons
                  :person="item.student"
                  :startDate="item.startDate"
                  :startTime="item.startTime"
                  :endDate="item.endDate"
                  :endTime="item.endTime"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />

          <v-btn text @click="dialog = false">Schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay z-index="999" v-model="loading">
      <v-progress-circular size="64" indeterminate> </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { justificationStatusColor } from "common/utils/icons.js";

import DateValue from "common/components/DateValue.vue";
import JustificationStatusMessage from "common/components/Absences/JustificationStatusMessage.vue";
import PersonItem from "@/components/PersonItem.vue";
import RegisterLessons from "common/components/Register/RegisterLessons.vue";
import LookupValue from "common/components/LookupValue.vue";

export default {
  components: {
    DateValue,
    JustificationStatusMessage,
    PersonItem,
    RegisterLessons,
    LookupValue,
  },
  props: ["justificationId"],
  data() {
    return {
      abortController: null,
      dialog: true,
      catDialog: false,
      categories: [],
      item: { status: {} },
      halfDayCount: {
        max: 5,
        used: 0,
      },
      lessonMap: [],
      loading: true,
      newCategory: null,
      updated: false,
    };
  },
  computed: {
    showHalfDayWarning48() {
      if (!this.item || !this.item.halfDay) {
        return false;
      }
      const creationLog = this.item.log.find(
        (el) => el.status.code == "created"
      );

      if (!creationLog) {
        return false;
      }
      const creationTime = new Date(
        creationLog.creationDate + "T" + creationLog.creationTime
      );

      const start = new Date(this.item.startDate + "T" + this.item.startTime);

      const delta =
        (start.getTime() - creationTime.getTime()) / (1000 * 60 * 60);
      return delta < 48;
    },
    showHalfDayWarning5() {
      return this.halfDayCount.max - this.halfDayCount.used <= 0;
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$router.push({ name: "PersonAbsences" });
      }
    },
    id() {
      this.fetchData();
    },
  },
  methods: {
    justificationStatusColor,

    async fetchData() {
      if (!this.justificationId) return;

      this.loading = true;
      this.categories = await this.apiList({
        resource: "absence/justificationcategory",
      });

      this.item = await this.apiGet({
        resource: "absence/justification",
        id: this.justificationId,
        onError: () => {
          this.$router.back();
          return;
        },
      });

      this.newCategory = this.item.category;

      this.halfDayCount = await this.apiGet({
        resource: "absence/halfday",
        id: this.item.student.id,
      });

      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
